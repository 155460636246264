<template>
  <div
    v-if="$root.env.isDevelopment"
    :data-command="command"
    :data-targetid="targetid"
    :data-config="config ? JSON.stringify(config) : null"
    :data-target="target"
    :data-fieldname="fieldname"
    :data-callback="callback ? callback.toString() : null"
    :data-data="data ? JSON.stringify(data) : null"
    :data-eventname="eventname"
    :data-parameters="parameters ? JSON.stringify(parameters) : null"
  />
</template>

<script>
const vm = {
  name: 'GTag',
  props: {
    // general
    command: {
      type: String,
      required: true,
      validator: value => ['config', 'get', 'set', 'event'].includes(value)
    },
    // config specific
    targetid: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: null
    },
    // get specific
    target: {
      type: String,
      default: null
    },
    fieldname: {
      type: String,
      default: null
    },
    callback: {
      type: Function,
      default: null
    },
    // set specific,
    data: {
      type: Object,
      default: null
    },
    // event specific,
    eventname: {
      type: String,
      default: null
    },
    parameters: {
      type: Object,
      default: null
    }
  },
  created () {
    if (this.command === 'config') {
      if (this.targetid) {
        window.gtag(this.command, this.targetid, this.config)
      } else {
        console.warn('GTag Error: required property "targetid" not set!')
      }
    } else if (this.command === 'get') {
      if (this.target && this.fieldname) {
        window.gtag(this.command, this.target, this.fieldname, this.callback)
      } else {
        console.warn('GTag Error: required properties "target" and/or "fieldname" not set!')
      }
    } else if (this.command === 'set') {
      if (this.data) {
        window.gtag(this.command, this.data)
      } else {
        console.warn('GTag Error: required property "data" not set!')
      }
    } else if (this.command === 'event') {
      if (this.eventname && this.parameters) {
        window.gtag(this.command, this.eventname, this.parameters)
      } else {
        console.warn('GTag Error: required property "eventname" and/or "parameters" not set!')
      }
    }
  }
}

export default vm
</script>
